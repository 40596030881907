export let exportMenu = [
	{
		icon: "uil-home-alt sidebar-icon",
		children: [
			{
				title: true,
				i18n: "MENU",
			},
			{
				url: "/dashboard",
				i18n: "HOME",
				icon: "uil-file-graph",
				active: false,
			},
			{
				url: "/analytic/tariff",
				i18n: "ANALYTIC",
				icon: "uil-file-graph",
				active: false,
			},
			{
				url: "/company",
				i18n: "COMPANY",
				icon: "uil-shop",
				active: false,
			},
			{
				url: "/tariff",
				i18n: "TARIFF",
				icon: "uil-bill",
				active: false,
			},
			{
				url: "/apartments",
				i18n: "APARTMENTS",
				icon: "uil-building",
				active: false,
			},
			{
				url: "/user",
				i18n: "CLIENTS",
				icon: "uil-user",
				active: false,
			},
			{
				url: "/actions",
				i18n: "ACTIONS_HISTORY",
				icon: "uil-history",
				active: false,
			},
		]
	},
]