import React from 'react'

import Login from '../pages/auth/Login'

import MobileLogin from '../pages/mobile/Login'

import PrivacyPolice from '../pages/PrivacyPolice'

import PaymentInfo from 'pages/PaymentInfo'

// ADMIN
import AdminCompany from '../pages/admin/company/Index'
import AdminCompanyCreateUpdate from '../pages/admin/company/CreateUpdate'

// WEB
import Dashboard from '../pages/dashboard/Index'

import AnalyticTariff from '../pages/analytic/Tariff'

import Company from '../pages/company/Index'

import Tariff from '../pages/tariff/Index'
import TariffCreateUpdate from '../pages/tariff/CreateUpdate'

import Apartments from '../pages/apartment/Index'

import Users from '../pages/users/Index'
import UsersActions from '../pages/users/Actions'
import UserPreview from '../pages/users/Preview'
import UserTariffPreview from '../pages/users/TariffPreview'

const publicRoutes = [
	{ path: "/auth/login", component: <Login /> },

	{ path: "/auth/mobile/login", component: <MobileLogin /> },

	{ path: "/privacy-police", component: <PrivacyPolice /> },
	{ path: "/mobile/privacy-police", component: <PrivacyPolice /> },
	{ path: "/mobile/privacy-police/:id", component: <PrivacyPolice /> },

	{ path: "/payment/info/:transactionId", component: <PaymentInfo /> },
]

const privateRoutes = [
	{ path: "/dashboard", component: <Dashboard /> },

	{ path: "/company", component: <Company /> },

	{ path: "/analytic/tariff", component: <AnalyticTariff /> },

	{ path: "/tariff", component: <Tariff /> },
	{ path: "/tariff/create", component: <TariffCreateUpdate /> },
	{ path: "/tariff/update/:id", component: <TariffCreateUpdate /> },

	{ path: "/apartments", component: <Apartments /> },

	{ path: "/user", component: <Users /> },
	{ path: "/actions", component: <UsersActions /> },
	{ path: "/user/:id", component: <UserPreview /> },
	{ path: "/user/tariff/:id", component: <UserTariffPreview /> }
]

const adminPrivateRoutes = [
	// ADMIN
	{ path: "/admin/company", component: <AdminCompany /> },
	{ path: "/admin/company/create", component: <AdminCompanyCreateUpdate /> },
	{ path: "/admin/company/update/:id", component: <AdminCompanyCreateUpdate /> },
]


export { publicRoutes, privateRoutes, adminPrivateRoutes }