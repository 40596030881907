import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useForm, Controller } from "react-hook-form";

import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'
import ConfirmModal from "parts/ConfirmModal"

import { DELETE, DOWNLOAD_FILE, FILE, GET, PGET, POST, PUT } from 'helpers/api'
import { formatDate, formatMoney } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const messageTypes = [
		{
			"id": 1,
			"name": "Пуш уведомление"
		},
		{
			"id": 2,
			"name": "СМС уведомление"
		},
		{
			"id": 3,
			"name": "Пуш и СМС уведомление "
		},
		{
			"id": 4,
			"name": "SMS уведомление если нет приложения Uget, если есть то пуш уведомление "
		}
	]

	const { register, handleSubmit, formState, control, watch, setValue, getValues, reset } = useForm({
		mode: "onChange"
	});
	const { errors } = formState
	const inform = watch('inform')

	const settings = useSelector(state => state.settings)

	const fileRef = useRef(null)

	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])
	const [history, setHistory] = useState([])

	const [showModal, setShowModal] = useState(false)
	const [showHistoryModal, setShowHistoryModal] = useState(false)
	const [showConfirmModal, setShowConfirmModal] = useState({ show: false, id: '' })

	async function createUpdate(validatedData) {
		var sendData = { ...validatedData }
		var response;
		if (sendData.id) {
			response = await PUT('/services/web/api/apartment', sendData, { loader: true })
		} else {
			response = await POST('/services/web/api/apartment', sendData, { loader: true })
		}

		if (response) {
			getData()
			setShowModal(false)
			reset()
		}
	}

	async function uploadExcel(e) {
		if (e.target?.files[0]) {
			let formData = new FormData();
			formData.append("file", e.target?.files[0]);
			const response = await FILE('/services/web/api/apartment/import', formData)
			if (response) {
				getData()
				fileRef.current.value = '';
			}
		}
	}

	async function downloadExcel() {
		const response = await DOWNLOAD_FILE('/services/web/api/apartment/export', t('APARTMENTS'))
		if (response) {
		}
	}

	function openModal(item) {
		var itemCopy = { ...item }
		for (const [key, value] of Object.entries(itemCopy)) {
			setValue(key, value)
		}
		setShowModal(true)
	}

	function closeModal() {
		setShowModal(false)
		reset()
	}

	async function openHistoryModal(item) {
		const response = await GET(`/services/web/api/apartment/${item.id}/payments`, {}, { loader: true })
		if (response) {
			setHistory(response)
			setShowHistoryModal(true)
		}
	}

	async function deleteItem() {
		const response = await DELETE('/services/web/api/apartment', { id: showConfirmModal.id }, { loader: true })
		if (response) {
			getData()
			setShowConfirmModal(false)
		}
	}

	async function getData() {
		const response = await PGET('/services/web/api/apartment/pageList', {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		const response = await PGET('/services/web/api/apartment/pageList', { page: data.selected, size: settings.size }, { loader: true })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('APARTMENTS')}
				</h4>

				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => setShowModal(true)}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex gap-3 mb-3">
						<Search url={'/services/web/api/clients-pageList'} search={search} getData={getData}></Search>

						<div>
							<button className="btn btn-outline-primary btn-wide" onClick={() => fileRef.current.click()}>
								<i className="uil uil-import me-2"></i>
								{t('UPLOAD_EXCEL')}
							</button>
							<input type="file" style={{ visibility: 'hidden', width: '0' }} ref={fileRef}
								onChangeCapture={uploadExcel}
								accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
							/>
						</div>

						<button className="btn-rounded btn btn-outline-primary" onClick={() => downloadExcel()}>
							<i className="uil uil-export me-2"></i>
							{t('DOWNLOAD_EXCEL')}
						</button>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('apartmentNumber')}</th>
									<th className="text-end">{t('floorNumber')}</th>
									<th className="text-end">{t('houseNumber')}</th>
									<th className="text-end">{t('tariffAmount')}</th>
									<th className="text-end">{t('balance')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.apartmentNumber}</td>
										<td className="text-end">{item.floorNumber}</td>
										<td className="text-end">{formatMoney(item.houseNumber)}</td>
										<td className="text-end">{formatMoney(item.tariffAmount)}</td>
										<td className="text-end">{formatMoney(item.balance)}</td>
										<td className="d-flex justify-content-center gap-2">
											<div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
												<i className="uil-edit-alt"></i>
											</div>
											<div className="table-action-button table-action-danger-button" onClick={() => setShowConfirmModal({ show: true, id: item.id })}>
												<i className="uil-trash-alt"></i>
											</div>

											<div className="table-action-button table-action-primary-button" onClick={() => openHistoryModal(item)}>
												<i className="uil-ellipsis-v"></i>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{getValues('id') ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(createUpdate)} autoComplete="off">
						<div className="form-group">
							<label>{t('apartmentNumber')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="apartmentNumber" {...register('apartmentNumber', { required: true })} />
							<span className="text-danger text-sm">{errors.apartmentNumber?.type === 'required' && t('required')}</span>
						</div>
						<div className="form-group">
							<label>{t('floorNumber')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="floorNumber" {...register('floorNumber', { required: true })} />
							<span className="text-danger text-sm">{errors.floorNumber?.type === 'required' && t('required')}</span>
						</div>
						<div className="form-group">
							<label>{t('houseNumber')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="houseNumber" {...register('houseNumber', { required: true })} />
							<span className="text-danger text-sm">{errors.houseNumber?.type === 'required' && t('required')}</span>
						</div>
						<div className="form-group">
							<label>{t('tariffAmount')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="tariffAmount" {...register('tariffAmount', { required: true })} />
							<span className="text-danger text-sm">{errors.tariffAmount?.type === 'required' && t('required')}</span>
						</div> 
						<div className="form-group">
							<label>{t('BALANCE')}</label>
							<input type="number" className="form-control" name="balance" {...register('balance')} />
						</div>
						{getValues('id') &&
							<>
								<div className="form-group d-flex justify-content-between align-items-center">
									<label>{t('inform')}</label>
									<div className="form-check form-switch form-switch-lg">
										<input type="checkbox" className="form-check-input"
											name="activated"
											{...register('inform')} />
									</div>
								</div>
								{
									inform &&
									<div className="form-group">
										<label>{t('TYPE')}</label>
										<Controller
											name="informTypeId"
											control={control}
											render={({ field }) =>
												<Select
													{...field}
													options={messageTypes}
													value={messageTypes.find(option => option.id === getValues('informTypeId'))}
													onChange={(e) => setValue('informTypeId', e.id)}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>}
										/>
									</div>
								}
							</>
						}
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			{/* MODAL */}
			<Modal show={showHistoryModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" size="xl" onHide={() => setShowHistoryModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t('HISTORY')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<table className="table">
						<thead>
							<tr>
								<th>{t('CARD_NUMBER')}</th>
								<th>UUID</th>
								<th className="text-end">{t('AMOUNT')}</th>
								<th className="text-center">{t('DATE')}</th>
								<th className="text-center">{t('STATUS')}</th>
								<th className="text-end">{t('refundAmount')}</th>
								<th className="text-center">{t('refundDateTime')}</th>
								<th className="text-center">{t('refundPaymentStatus')}</th>
							</tr>
						</thead>
						<tbody>
							{history?.map((item, index) => (
								<tr key={index}>
									<td>{item.maskedPan}</td>
									<td>{item.shopTransactionUUID}</td>
									<td className="text-end">{formatMoney(item.amount)}</td>
									<td className="text-center">{formatDate(item.paymentDateTime)}</td>
									<td className="text-center">{item.paymentStatus}</td>
									<td className="text-end">{formatMoney(item.refundAmount)}</td>
									<td className="text-center">{formatDate(item.refundDateTime)}</td>
									<td className="text-center">{item.refundPaymentStatus}</td>
								</tr>
							))
							}
						</tbody>
					</table>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			<ConfirmModal modalConfirm={showConfirmModal.show} setModalConfirm={setShowConfirmModal} confrim={deleteItem} />
		</>
	)
}

export default Index
